import React from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from "./Dashboard.module.scss";
import { Button } from "components/primaryComponents";
import useDashboard from "./useDashboard";
import { formatCurrency, formatPoints } from "helpers";

const Dashboard = () => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const { first_name, last_name, loyalty_wallet, brand_name, currency_code } =
    useDashboard();

  return (
    <div className={styles.dashboard}>
      <h6>
        <span className="font-weight-semibold">Welcome to {brand_name} </span>
        Rewards
      </h6>
      {/* card */}
      <div className={styles.dashboard__card}>
        <h6 className="white--text font-weight-semibold mb-1">
          Available {brand_name} Reward
        </h6>
        <div className="d-flex justify-content-between">
          <h4
            style={{ fontSize: "24px" }}
            className="font-weight-semibold white--text"
          >
            {formatPoints(loyalty_wallet?.balance)}
          </h4>

          <h5 className="font-weight-semibold white--text">
            {formatCurrency({
              value: loyalty_wallet?.balance_naira_equiv,
              decimal: 0,
              currencyCode: currency_code,
            })}
          </h5>
        </div>

        <div className="mt-auto d-flex justify-content-between">
          {/* <div>
            <p className="white--text small-text-size">Naira Value</p>
            <p className="white--text">
              {formatCurrency(loyalty_wallet?.balance_naira_equiv)}{" "}
            </p>
          </div> */}
          <div className="">
            <p
              className="white--text mb-1 ml-auto"
              style={{
                maxWidth: "150px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                textTransform: "capitalize",
              }}
            >
              {first_name} {last_name}
            </p>
            <Button
              classNames={`btn btn--outline  ${styles.btn}`}
              onClick={() => navigate("/app/rewards")}
            >
              View rewards
            </Button>
          </div>
        </div>
      </div>

      {/* tabs header */}
      <div className={styles.dashboard__tab}>
        <NavLink
          to="gift-cards"
          className={[
            styles.tabItem,
            `${pathname.includes("/app/gift-cards") ? styles.active : ""}`,
          ].join(" ")}
        >
          Explore
        </NavLink>
        <NavLink
          className={[
            styles.tabItem,
            `${pathname.includes("/app/history") ? styles.active : ""}`,
          ].join(" ")}
          to="history/earnings"
        >
          History
        </NavLink>
      </div>
      <div className={styles.dashboard__content}>
        <Outlet />
      </div>
    </div>
  );
};
export default Dashboard;
