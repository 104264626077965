import React from "react";
import {
  LoaderHelper,
  MetaSetter,
  RequestLoaderTrigger,
} from "components/secondaryComponents";
import { motion } from "framer-motion";
import styles from "./Earnings.module.scss";
import useEarnings from "./useEarnings";
//import { formatPoints } from "helpers";
import icons from "assets/icons";

const Earnings = () => {
  const { isLoading, data } = useEarnings();
  return (
    <MetaSetter
      title="Lumi Merchant Loyalty | Dashboard - Earning history"
      description="View history"
    >
      <RequestLoaderTrigger>
        <motion.div
          initial={{ y: 50 }}
          animate={{ y: 0 }}
          transition={{ delay: 0.2 }}
          className={styles.earnings}
        >
          <LoaderHelper isLoading={isLoading} classNames="mt-5">
            <div className={styles.earnings__items}>
              {data?.pages[0].data?.length > 0 ? (
                <>
                  {data?.pages.map((page) =>
                    page?.data.map((item) => (
                      <div className={styles.earningItem} key={item?.id}>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <p className="small-text-size dark--text">
                            {new Date(item?.created_at).toLocaleString("en-us")}
                          </p>
                          <h6
                            className={`${styles.points} ml-auto`}
                            style={{ width: "fit-content" }}
                          >
                            {item?.naira_equiv}
                          </h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <p style={{ textTransform: "capitalize" }}>
                            Type:{" "}
                            <span
                              className={
                                item?.type === "debit"
                                  ? "error--text"
                                  : "success--text"
                              }
                            >
                              {item?.type}
                            </span>
                          </p>
                          <p>
                            Reference:{" "}
                            <span className="dark--text font-weight-semibold">
                              {item?.reference}
                            </span>
                          </p>
                        </div>
                      </div>
                    )),
                  )}
                </>
              ) : (
                <div className="text-center mt-5">
                  <img
                    width="211px"
                    src={icons.giftCardEmpty}
                    alt="emptyState"
                  />
                  <h5>No Earnings Available yet</h5>
                </div>
              )}
            </div>
          </LoaderHelper>
        </motion.div>
      </RequestLoaderTrigger>
    </MetaSetter>
  );
};
export default Earnings;
